exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-buy-index-tsx": () => import("./../../../src/pages/buy/index.tsx" /* webpackChunkName: "component---src-pages-buy-index-tsx" */),
  "component---src-pages-buy-oem-tsx": () => import("./../../../src/pages/buy/oem.tsx" /* webpackChunkName: "component---src-pages-buy-oem-tsx" */),
  "component---src-pages-buy-products-tsx": () => import("./../../../src/pages/buy/products.tsx" /* webpackChunkName: "component---src-pages-buy-products-tsx" */),
  "component---src-pages-buy-thankyou-tsx": () => import("./../../../src/pages/buy/thankyou.tsx" /* webpackChunkName: "component---src-pages-buy-thankyou-tsx" */),
  "component---src-pages-demos-all-defaults-tsx": () => import("./../../../src/pages/demos/all-defaults.tsx" /* webpackChunkName: "component---src-pages-demos-all-defaults-tsx" */),
  "component---src-pages-demos-all-features-tsx": () => import("./../../../src/pages/demos/all-features.tsx" /* webpackChunkName: "component---src-pages-demos-all-features-tsx" */),
  "component---src-pages-demos-cropro-all-defaults-tsx": () => import("./../../../src/pages/demos/cropro/all-defaults.tsx" /* webpackChunkName: "component---src-pages-demos-cropro-all-defaults-tsx" */),
  "component---src-pages-demos-cropro-aspect-ratios-tsx": () => import("./../../../src/pages/demos/cropro/aspect-ratios.tsx" /* webpackChunkName: "component---src-pages-demos-cropro-aspect-ratios-tsx" */),
  "component---src-pages-demos-cropro-coming-soon-tsx": () => import("./../../../src/pages/demos/cropro/coming-soon.tsx" /* webpackChunkName: "component---src-pages-demos-cropro-coming-soon-tsx" */),
  "component---src-pages-demos-cropro-customize-ui-tsx": () => import("./../../../src/pages/demos/cropro/customize-ui.tsx" /* webpackChunkName: "component---src-pages-demos-cropro-customize-ui-tsx" */),
  "component---src-pages-demos-cropro-no-ui-tsx": () => import("./../../../src/pages/demos/cropro/no-ui.tsx" /* webpackChunkName: "component---src-pages-demos-cropro-no-ui-tsx" */),
  "component---src-pages-demos-cropro-popup-tsx": () => import("./../../../src/pages/demos/cropro/popup.tsx" /* webpackChunkName: "component---src-pages-demos-cropro-popup-tsx" */),
  "component---src-pages-demos-customize-ui-tsx": () => import("./../../../src/pages/demos/customize-ui.tsx" /* webpackChunkName: "component---src-pages-demos-customize-ui-tsx" */),
  "component---src-pages-demos-diagram-coming-soon-tsx": () => import("./../../../src/pages/demos/diagram/coming-soon.tsx" /* webpackChunkName: "component---src-pages-demos-diagram-coming-soon-tsx" */),
  "component---src-pages-demos-diagram-customize-ui-tsx": () => import("./../../../src/pages/demos/diagram/customize-ui.tsx" /* webpackChunkName: "component---src-pages-demos-diagram-customize-ui-tsx" */),
  "component---src-pages-demos-diagram-diagrams-app-tsx": () => import("./../../../src/pages/demos/diagram/diagrams-app.tsx" /* webpackChunkName: "component---src-pages-demos-diagram-diagrams-app-tsx" */),
  "component---src-pages-demos-diagram-flowchart-tsx": () => import("./../../../src/pages/demos/diagram/flowchart.tsx" /* webpackChunkName: "component---src-pages-demos-diagram-flowchart-tsx" */),
  "component---src-pages-demos-diagram-getting-started-tsx": () => import("./../../../src/pages/demos/diagram/getting-started.tsx" /* webpackChunkName: "component---src-pages-demos-diagram-getting-started-tsx" */),
  "component---src-pages-demos-diagram-mindmap-tsx": () => import("./../../../src/pages/demos/diagram/mindmap.tsx" /* webpackChunkName: "component---src-pages-demos-diagram-mindmap-tsx" */),
  "component---src-pages-demos-diagram-network-tsx": () => import("./../../../src/pages/demos/diagram/network.tsx" /* webpackChunkName: "component---src-pages-demos-diagram-network-tsx" */),
  "component---src-pages-demos-diagram-orgchart-tsx": () => import("./../../../src/pages/demos/diagram/orgchart.tsx" /* webpackChunkName: "component---src-pages-demos-diagram-orgchart-tsx" */),
  "component---src-pages-demos-events-tsx": () => import("./../../../src/pages/demos/events.tsx" /* webpackChunkName: "component---src-pages-demos-events-tsx" */),
  "component---src-pages-demos-index-tsx": () => import("./../../../src/pages/demos/index.tsx" /* webpackChunkName: "component---src-pages-demos-index-tsx" */),
  "component---src-pages-demos-markerjs-live-all-defaults-tsx": () => import("./../../../src/pages/demos/markerjs-live/all-defaults.tsx" /* webpackChunkName: "component---src-pages-demos-markerjs-live-all-defaults-tsx" */),
  "component---src-pages-demos-markerjs-live-events-tsx": () => import("./../../../src/pages/demos/markerjs-live/events.tsx" /* webpackChunkName: "component---src-pages-demos-markerjs-live-events-tsx" */),
  "component---src-pages-demos-markerjs-live-plugins-tsx": () => import("./../../../src/pages/demos/markerjs-live/plugins.tsx" /* webpackChunkName: "component---src-pages-demos-markerjs-live-plugins-tsx" */),
  "component---src-pages-demos-markerjs-live-responsive-tsx": () => import("./../../../src/pages/demos/markerjs-live/responsive.tsx" /* webpackChunkName: "component---src-pages-demos-markerjs-live-responsive-tsx" */),
  "component---src-pages-demos-markers-tsx": () => import("./../../../src/pages/demos/markers.tsx" /* webpackChunkName: "component---src-pages-demos-markers-tsx" */),
  "component---src-pages-demos-more-demos-tsx": () => import("./../../../src/pages/demos/more-demos.tsx" /* webpackChunkName: "component---src-pages-demos-more-demos-tsx" */),
  "component---src-pages-demos-popup-tsx": () => import("./../../../src/pages/demos/popup.tsx" /* webpackChunkName: "component---src-pages-demos-popup-tsx" */),
  "component---src-pages-demos-save-state-tsx": () => import("./../../../src/pages/demos/save-state.tsx" /* webpackChunkName: "component---src-pages-demos-save-state-tsx" */),
  "component---src-pages-docs-configuring-defaults-tsx": () => import("./../../../src/pages/docs/configuring-defaults.tsx" /* webpackChunkName: "component---src-pages-docs-configuring-defaults-tsx" */),
  "component---src-pages-docs-cropro-coming-soon-tsx": () => import("./../../../src/pages/docs/cropro/coming-soon.tsx" /* webpackChunkName: "component---src-pages-docs-cropro-coming-soon-tsx" */),
  "component---src-pages-docs-cropro-configure-aspect-ratios-tsx": () => import("./../../../src/pages/docs/cropro/configure-aspect-ratios.tsx" /* webpackChunkName: "component---src-pages-docs-cropro-configure-aspect-ratios-tsx" */),
  "component---src-pages-docs-cropro-configure-defaults-tsx": () => import("./../../../src/pages/docs/cropro/configure-defaults.tsx" /* webpackChunkName: "component---src-pages-docs-cropro-configure-defaults-tsx" */),
  "component---src-pages-docs-cropro-getting-started-tsx": () => import("./../../../src/pages/docs/cropro/getting-started.tsx" /* webpackChunkName: "component---src-pages-docs-cropro-getting-started-tsx" */),
  "component---src-pages-docs-cropro-no-ui-tsx": () => import("./../../../src/pages/docs/cropro/no-ui.tsx" /* webpackChunkName: "component---src-pages-docs-cropro-no-ui-tsx" */),
  "component---src-pages-docs-cropro-react-walkthrough-tsx": () => import("./../../../src/pages/docs/cropro/react-walkthrough.tsx" /* webpackChunkName: "component---src-pages-docs-cropro-react-walkthrough-tsx" */),
  "component---src-pages-docs-cropro-rendering-options-tsx": () => import("./../../../src/pages/docs/cropro/rendering-options.tsx" /* webpackChunkName: "component---src-pages-docs-cropro-rendering-options-tsx" */),
  "component---src-pages-docs-cropro-ui-customization-tsx": () => import("./../../../src/pages/docs/cropro/ui-customization.tsx" /* webpackChunkName: "component---src-pages-docs-cropro-ui-customization-tsx" */),
  "component---src-pages-docs-cropro-vue-walkthrough-tsx": () => import("./../../../src/pages/docs/cropro/vue-walkthrough.tsx" /* webpackChunkName: "component---src-pages-docs-cropro-vue-walkthrough-tsx" */),
  "component---src-pages-docs-custom-fonts-tsx": () => import("./../../../src/pages/docs/custom-fonts.tsx" /* webpackChunkName: "component---src-pages-docs-custom-fonts-tsx" */),
  "component---src-pages-docs-customizing-available-markers-tsx": () => import("./../../../src/pages/docs/customizing-available-markers.tsx" /* webpackChunkName: "component---src-pages-docs-customizing-available-markers-tsx" */),
  "component---src-pages-docs-diagram-coming-soon-tsx": () => import("./../../../src/pages/docs/diagram/coming-soon.tsx" /* webpackChunkName: "component---src-pages-docs-diagram-coming-soon-tsx" */),
  "component---src-pages-docs-diagram-customize-ui-tsx": () => import("./../../../src/pages/docs/diagram/customize-ui.tsx" /* webpackChunkName: "component---src-pages-docs-diagram-customize-ui-tsx" */),
  "component---src-pages-docs-diagram-diagram-settings-tsx": () => import("./../../../src/pages/docs/diagram/diagram-settings.tsx" /* webpackChunkName: "component---src-pages-docs-diagram-diagram-settings-tsx" */),
  "component---src-pages-docs-diagram-editor-settings-tsx": () => import("./../../../src/pages/docs/diagram/editor-settings.tsx" /* webpackChunkName: "component---src-pages-docs-diagram-editor-settings-tsx" */),
  "component---src-pages-docs-diagram-events-tsx": () => import("./../../../src/pages/docs/diagram/events.tsx" /* webpackChunkName: "component---src-pages-docs-diagram-events-tsx" */),
  "component---src-pages-docs-diagram-getting-started-tsx": () => import("./../../../src/pages/docs/diagram/getting-started.tsx" /* webpackChunkName: "component---src-pages-docs-diagram-getting-started-tsx" */),
  "component---src-pages-docs-diagram-stencilsets-tsx": () => import("./../../../src/pages/docs/diagram/stencilsets.tsx" /* webpackChunkName: "component---src-pages-docs-diagram-stencilsets-tsx" */),
  "component---src-pages-docs-diagram-viewer-vs-editor-tsx": () => import("./../../../src/pages/docs/diagram/viewer-vs-editor.tsx" /* webpackChunkName: "component---src-pages-docs-diagram-viewer-vs-editor-tsx" */),
  "component---src-pages-docs-diagram-with-react-tsx": () => import("./../../../src/pages/docs/diagram/with-react.tsx" /* webpackChunkName: "component---src-pages-docs-diagram-with-react-tsx" */),
  "component---src-pages-docs-display-mode-tsx": () => import("./../../../src/pages/docs/display-mode.tsx" /* webpackChunkName: "component---src-pages-docs-display-mode-tsx" */),
  "component---src-pages-docs-events-tsx": () => import("./../../../src/pages/docs/events.tsx" /* webpackChunkName: "component---src-pages-docs-events-tsx" */),
  "component---src-pages-docs-extend-markerjs-tsx": () => import("./../../../src/pages/docs/extend-markerjs.tsx" /* webpackChunkName: "component---src-pages-docs-extend-markerjs-tsx" */),
  "component---src-pages-docs-getting-started-tsx": () => import("./../../../src/pages/docs/getting-started.tsx" /* webpackChunkName: "component---src-pages-docs-getting-started-tsx" */),
  "component---src-pages-docs-index-tsx": () => import("./../../../src/pages/docs/index.tsx" /* webpackChunkName: "component---src-pages-docs-index-tsx" */),
  "component---src-pages-docs-markerjs-live-creating-plugins-tsx": () => import("./../../../src/pages/docs/markerjs-live/creating-plugins.tsx" /* webpackChunkName: "component---src-pages-docs-markerjs-live-creating-plugins-tsx" */),
  "component---src-pages-docs-markerjs-live-elements-tsx": () => import("./../../../src/pages/docs/markerjs-live/elements.tsx" /* webpackChunkName: "component---src-pages-docs-markerjs-live-elements-tsx" */),
  "component---src-pages-docs-markerjs-live-events-tsx": () => import("./../../../src/pages/docs/markerjs-live/events.tsx" /* webpackChunkName: "component---src-pages-docs-markerjs-live-events-tsx" */),
  "component---src-pages-docs-markerjs-live-getting-started-tsx": () => import("./../../../src/pages/docs/markerjs-live/getting-started.tsx" /* webpackChunkName: "component---src-pages-docs-markerjs-live-getting-started-tsx" */),
  "component---src-pages-docs-markerjs-live-plugins-tsx": () => import("./../../../src/pages/docs/markerjs-live/plugins.tsx" /* webpackChunkName: "component---src-pages-docs-markerjs-live-plugins-tsx" */),
  "component---src-pages-docs-markerjs-live-react-walkthrough-tsx": () => import("./../../../src/pages/docs/markerjs-live/react-walkthrough.tsx" /* webpackChunkName: "component---src-pages-docs-markerjs-live-react-walkthrough-tsx" */),
  "component---src-pages-docs-markerjs-live-vue-walkthrough-tsx": () => import("./../../../src/pages/docs/markerjs-live/vue-walkthrough.tsx" /* webpackChunkName: "component---src-pages-docs-markerjs-live-vue-walkthrough-tsx" */),
  "component---src-pages-docs-react-walkthrough-tsx": () => import("./../../../src/pages/docs/react-walkthrough.tsx" /* webpackChunkName: "component---src-pages-docs-react-walkthrough-tsx" */),
  "component---src-pages-docs-rendering-options-tsx": () => import("./../../../src/pages/docs/rendering-options.tsx" /* webpackChunkName: "component---src-pages-docs-rendering-options-tsx" */),
  "component---src-pages-docs-saving-state-tsx": () => import("./../../../src/pages/docs/saving-state.tsx" /* webpackChunkName: "component---src-pages-docs-saving-state-tsx" */),
  "component---src-pages-docs-ui-customization-tsx": () => import("./../../../src/pages/docs/ui-customization.tsx" /* webpackChunkName: "component---src-pages-docs-ui-customization-tsx" */),
  "component---src-pages-docs-vue-walkthrough-tsx": () => import("./../../../src/pages/docs/vue-walkthrough.tsx" /* webpackChunkName: "component---src-pages-docs-vue-walkthrough-tsx" */),
  "component---src-pages-download-cropro-tsx": () => import("./../../../src/pages/download/cropro.tsx" /* webpackChunkName: "component---src-pages-download-cropro-tsx" */),
  "component---src-pages-download-diagram-tsx": () => import("./../../../src/pages/download/diagram.tsx" /* webpackChunkName: "component---src-pages-download-diagram-tsx" */),
  "component---src-pages-download-markerjs-live-tsx": () => import("./../../../src/pages/download/markerjs-live.tsx" /* webpackChunkName: "component---src-pages-download-markerjs-live-tsx" */),
  "component---src-pages-download-markerjs-tsx": () => import("./../../../src/pages/download/markerjs.tsx" /* webpackChunkName: "component---src-pages-download-markerjs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-l-amcharts-tsx": () => import("./../../../src/pages/l/amcharts.tsx" /* webpackChunkName: "component---src-pages-l-amcharts-tsx" */),
  "component---src-pages-license-oem-20201201-tsx": () => import("./../../../src/pages/license/oem-20201201.tsx" /* webpackChunkName: "component---src-pages-license-oem-20201201-tsx" */),
  "component---src-pages-license-single-app-20201201-tsx": () => import("./../../../src/pages/license/single-app-20201201.tsx" /* webpackChunkName: "component---src-pages-license-single-app-20201201-tsx" */),
  "component---src-pages-license-unlimited-apps-20201201-tsx": () => import("./../../../src/pages/license/unlimited-apps-20201201.tsx" /* webpackChunkName: "component---src-pages-license-unlimited-apps-20201201-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-products-cropro-index-tsx": () => import("./../../../src/pages/products/cropro/index.tsx" /* webpackChunkName: "component---src-pages-products-cropro-index-tsx" */),
  "component---src-pages-products-diagram-index-tsx": () => import("./../../../src/pages/products/diagram/index.tsx" /* webpackChunkName: "component---src-pages-products-diagram-index-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-products-markerjs-index-tsx": () => import("./../../../src/pages/products/markerjs/index.tsx" /* webpackChunkName: "component---src-pages-products-markerjs-index-tsx" */),
  "component---src-pages-products-markerjs-live-index-tsx": () => import("./../../../src/pages/products/markerjs-live/index.tsx" /* webpackChunkName: "component---src-pages-products-markerjs-live-index-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

